// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

// set no href anchor elements
.link-href {
  cursor: pointer;
}

// use menu formatting on page elements
.link-activate {
    transition: all 0.4s;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: $text-muted;
        text-decoration: none;
    }
}

// used on landing page footer
.link-highlight {
    color: rgba(255,255,255,.5);
    transition: all 0.4s;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.nav-link {
    cursor: pointer;
}

// size posion log group thumbnail
.position-group-logo {
  display: inline-block;
  height: 32px;
  width: 32px;
}


.calender-market-event {
  overflow: hidden;
}

@media (max-width: 767.98px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: left;
    }

    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
}

// landing page element order
.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}